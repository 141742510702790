import { render, staticRenderFns } from "./DashboardCustomFab.vue?vue&type=template&id=68196340&scoped=true"
import script from "./DashboardCustomFab.vue?vue&type=script&lang=js"
export * from "./DashboardCustomFab.vue?vue&type=script&lang=js"
import style0 from "./DashboardCustomFab.vue?vue&type=style&index=0&id=68196340&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68196340",
  null
  
)

export default component.exports